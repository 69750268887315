import Config from '../Config';
import {RestService} from './RestService';

export const UserService = {

    authenticate: async function(body, headers = {}){
        return RestService.post(Config.authenticate_url, headers, body)
                .then(response => response.json());
    },
    authenticateToken: async function(body, headers = {}){
        return RestService.post(Config.authenticate_token_url, headers, body)
                .then(response => response.json());
    },
};