import React from 'react';
import { withRouter, useHistory } from "react-router-dom";
import './css/App.css';
import Routes from "./routes";
import MySnackbarContent from './components/SnackBar';
import ProgressDialog from './components/ProgressDialog';
import {
  Snackbar,
  Dialog as DialogMUI,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
  CssBaseline,
  Fade,
  LinearProgress,
  FormControl,
  OutlinedInput,
  ThemeProvider,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import MyAppBar from './MyAppBar';
import { AppContext } from './AppContextProvider';
import { useContext } from 'react';
import { UserService } from './Services/UserService';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	sectionMobile: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
		display: 'flex',
		},
	},
	accountCircle: {
		marginRight: theme.spacing(3)
	},
}));


function App(props) {

	const classes = useStyles();
	const {state,
		handleSBClose,
		closeConfirmDialog,
		closeConfirmDialog1D,
		userHasAuthenticated,
		handleLogout,
		updateUser,
		setAuthenticating,
		showProgressDialog,
		closeProgressDialog,
		setDialogFieldText,
	} = useContext(AppContext);
	const history = useHistory();

	React.useEffect(() => {

		if(localStorage.getItem('acumen.status.session') === null) {
			history.push("/");
		}
		try {
			if (state.user !== null) {
				userHasAuthenticated(true);
			}
			if(localStorage.getItem('acumen.status.session')){
				userHasAuthenticated(true);
				showProgressDialog('Loading...');
				UserService.authenticateToken()
				.then((data) => {
					if (data.id) {
						updateUser(data);
						localStorage.setItem('acumen.status.user', JSON.stringify(data));
						localStorage.setItem('acumen.status.session', data['sysbrew-x-token']);
						userHasAuthenticated(true);
						history.push(props.location.pathname);
					} else {
						handleLogout();
						history.push("/");
					}
					closeProgressDialog();
					setAuthenticating(false);
				})
				.catch((error) => {
					handleLogout();
					closeProgressDialog();
					setAuthenticating(false);
				});
				closeProgressDialog();
			}
		} catch(e) {
			if (e !== 'No current user') {
				alert(e);
			}
			closeProgressDialog();
			setAuthenticating(false);
		}
		
		return () => {

		}
	}, [true]);

    return (
        <ThemeProvider theme={state.theme}>
			<div>
				<div className={classes.root}>
					<Fade
						in={state.linearLoading}
						style={{
							transitionDelay: state.linearLoading ? '50ms' : '0ms',
						}}
						unmountOnExit
						>
						<LinearProgress 
							id="test" 
							style={{height: 5, position: "fixed", top: 0, width: "100%"}} />
					</Fade>
					<CssBaseline />
					{
						state.isAuthenticated && (
							<MyAppBar />
						)
					}
				</div>
				<Routes style={{width: "50%"}}/>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={state.sb.open}
					autoHideDuration={state.sb.autohide}
					onClose={handleSBClose}>
						<MySnackbarContent
							onClose={handleSBClose}
							variant={state.sb.varient}
							message={state.sb.message}
						/>
				</Snackbar>
				<ProgressDialog open={state.progressOpen} message={state.progressMessage}/>
				<DialogMUI
					style={{zIndex: 2000}}
					disableEnforceFocus
					open={state.openDialog}
					onClose={closeConfirmDialog}
					fullWidth={true}
					maxWidth='sm'
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
						{state.dialog_title ? <DialogTitle id="alert-dialog-title">{state.dialog_title}</DialogTitle> : ''}
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{state.dialog_message}
							</DialogContentText>
							{
								state.showfield && 
								<div>
									<FormControl variant="outlined" key="field" fullWidth>
										<OutlinedInput
											fullWidth
											placeholder={state.fieldHint}
											defaultValue={state.fieldValue}
											id="outlined-adornment-field"
											onChange={(e) => { 
												e.target.value && e.target.value.length > 0 && setDialogFieldText(e.target.value) }}
											aria-describedby="outlined-weight-helper-text"
											inputProps={{
												'aria-label': 'rate',
											}}
											labelWidth={0}
											multiline
											error={state.fieldError || false}
											rowsMax={2}
										/>
										{
											state.fieldError && 
											<FormHelperText error={true}>{state.fieldError}</FormHelperText>
										}
									</FormControl>
								</div>
							}
						</DialogContent>
						<DialogActions>
							<Button onClick={closeConfirmDialog} color="primary">
								{state.confirmAction ? state.cancel_title : "Ok"}
							</Button>
							{
								state.confirmAction &&
								<Button onClick={(e) => 
									state.showfield ? 
										state.confirmAction(state.dialog_field_text) 
									: 
										state.confirmAction()
									} 
									color="primary" 
									autoFocus>
										{state.accept_title}
								</Button>
							}
						</DialogActions>
				</DialogMUI>
				<DialogMUI
					style={{zIndex: 2000}}
					disableEnforceFocus
					open={state.openDialog_1D}
					onClose={closeConfirmDialog1D}
					fullWidth={true}
					maxWidth='sm'
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
						{state.dialog_title_1D ? <DialogTitle id="alert-dialog-title">{state.dialog_title_1D}</DialogTitle> : ''}
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{state.dialog_message_1D}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button 
								onClick={() => {
									closeConfirmDialog();
									if(state.confirmAction_1D){
										state.confirmAction_1D();
									}
								}} color="primary">
								{state.button_title_1D ? state.button_title_1D : "Ok"}
							</Button>
						</DialogActions>
				</DialogMUI>
			</div>
        </ThemeProvider>
    );
}

export default withRouter(App);
