const host = process.env.NODE_ENV === 'production' ? "https://api.franchisee.acumengroup.in" : "https://api.franchisee.acumengroup.in";

const Config = {

    authenticate_url: `${host}/authenticate/lead`,
    authenticate_token_url: `${host}/authenticate/lead/token`,
    get_lead_status_url: `${host}/lead/status/{id}`,
    save_step_url: `${host}/lead/step/{lead}`,
    
    REFRESH_INTERVAL: 300000
}

export default Config;