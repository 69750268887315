import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./HomePage/home";
import Login from "./LoginPage/Login";
import NotFound from "./NotFound/NotFound";

export default () =>
  <Switch>
    <Route path={`/`} exact component={Login} />
    <Route path={`/status`} exact component={Home} />
    
    {/* Not found */}
    <Route component={NotFound} />
  </Switch>;