import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Grid,
    // Divider, 
    ListItemText,
    Typography } from '@material-ui/core';
import { formatNumber, getTimeInIST } from '../Utils';
import { AppContext } from '../AppContextProvider';

const useStyles = makeStyles((theme) => ({
	root:{
        display: 'flex', 
        width: '100%', 
        marginTop: theme.spacing(1), 
        marginBottom: theme.spacing(1)
    },
    right: {
        flex: 0.5, 
        textAlign: 'end',
        marginTop: theme.spacing(1), 
    },
    left: {
        flex: 1, 
        textAlign: 'start',
        marginTop: theme.spacing(1), 
    },
    section: {
        flex: 0.5,
        marginLeft: theme.spacing(2),
    },
    sectionFull: {
        flex: 1,
        marginTop: 6,
        marginBottom: 6
    },
    formControlAutoComplete: {
        zIndex: 3,
    },
    icon: {
        color: 'white'
    },  
}));

// function DummyBlock({step}){
//     const classes = useStyles();

//     return (
//         <div className={classes.sectionFull}>
//             {/* <Typography variant="body1">{`Comments: ${step.comments || '-'}`}</Typography> */}
//         </div>
//     );
// }

const CustomStepLabel = (props) => {
    const {step, lead} = props;
    const classes = useStyles();
    const {mobileView,} = useContext(AppContext);

    const cDate = step.modified_date ? getTimeInIST(step.modified_date).fromNow() : '-';
    
    return (
        <div>
            <div className={classes.root}>
                <div className={mobileView ? classes.sectionFull : classes.section}>
                    <ListItemText
                        primary={step.status_title? step.status_title : step.name}
                        primaryTypographyProps={{align: 'left', variant: 'subtitle1'}}
                        secondary={mobileView && (step.approved !== 0 && step.approved) && `Completed ${cDate}`}
                        secondaryTypographyProps={{align: 'left', variant: 'caption'}}
                        />
                    {
                        step.status_description && (
                            <Typography variant="body1" align="left" component="div">
                                {
                                    step.status_description.split('\n').map((element,i) => <div key={i}>{element}</div>)
                                }
                            </Typography>
                        )
                    }
                    {
                        step.step_id === '9c9acad7-13d6-4454-b408-0140ac257a43' && (
                            <div>
                                <br/>
                                <Typography variant="subtitle2" align="left">Charges are as Follows</Typography>
                                <div style={{width: mobileView ? '100%' : '50%'}}>
                                    <Grid container spacing={2} justify="space-between">
                                        {
                                            lead.option_mcx === 1 && (
                                                <Grid item xs={6}><Typography align="left">MCX</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_mcx === 1 && (
                                                <Grid item xs={6}><Typography align="right">{formatNumber(lead.charges_mcx)}</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_ncdex === 1 && (
                                                <Grid item xs={6}><Typography align="left">NCDEX</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_ncdex === 1 && (
                                                <Grid item xs={6}><Typography align="right">{formatNumber(lead.charges_mcx)}</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_nse_cash === 1 && (
                                                <Grid item xs={6}><Typography align="left">NSE Cash</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_nse_cash === 1 && (
                                                <Grid item xs={6}><Typography align="right">{formatNumber(lead.charges_nse_cash)}</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_nse_fo === 1 && (
                                                <Grid item xs={6}><Typography align="left">NSE F&O</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_nse_fo === 1 && (
                                                <Grid item xs={6}><Typography align="right">{formatNumber(lead.charges_nse_fo)}</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_nse_currency === 1 && (
                                                <Grid item xs={6}><Typography align="left">NSE Currencies</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_nse_currency === 1 && (
                                                <Grid item xs={6}><Typography align="right">{formatNumber(lead.charges_nse_currency)}</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_bse_cash === 1 && (
                                                <Grid item xs={6}><Typography align="left">BSE Cash</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_bse_cash === 1 && (
                                                <Grid item xs={6}><Typography align="right">{formatNumber(lead.charges_bse_cash)}</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_bse_fo === 1 && (
                                                <Grid item xs={6}><Typography align="left">BSE F&O</Typography></Grid>
                                            )
                                        }
                                        {
                                            lead.option_bse_fo === 1 && (
                                                <Grid item xs={6}><Typography align="right">{formatNumber(lead.charges_bse_fo)}</Typography></Grid>
                                            )
                                        }
                                        <Grid item xs={6}><Typography align="left">Software Charges</Typography></Grid><Grid item xs={6}><Typography align="right">{formatNumber(lead.charges_software)}</Typography></Grid>
                                    </Grid>
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    !mobileView && (step.approved !== 0 && step.approved) && (
                        <div className={classes.right}>
                            <Typography variant="caption">{`Completed ${cDate}`}</Typography>
                        </div>
                    )
                }
            </div>
            {/* <Divider /> */}
        </div>
    )
};

export default CustomStepLabel;