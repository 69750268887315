import Config from '../Config';
import {RestService} from './RestService';

export const LeadService = {
    getLeadStatus: async function(id = "") {
        return RestService.get(Config.get_lead_status_url.replace("{id}", id), {})
        .then(response => response.json());
    },
    saveStep: async function(lead, body, headers = {}) {
        return RestService.post(Config.save_step_url.replace("{lead}", lead), headers, body)
        .then(response => response.json());
    },
};