export const STATUS = {
  STARTED: 1,
  CLOSED: 2,
  REJECTED: -1,
};

export const USERTYPE_TO_TEXT = {
  'BRANCH': 'Branch / BM',
  'ACE' : 'ACE',
  'RMS': 'RMS',
  'FRANSPRT': 'Franchisee Support',
  'TECH': 'Tech Support',
  'SALEHD': 'Sales Head',
  'STATEMG': 'State Manager',
  'CCARE': 'Customer Care',
  'LEAD': 'Lead',
}

export const USERTYPES = {
  'BRANCH': 'BRANCH',
  'ACE' : 'ACE',
  'RMS': 'RMS',
  'FRANSPRT': 'FRANSPRT',
  'TECH': 'TECH',
  'SALEHD': 'SALEHD',
  'STATEMG': 'STATEMG',
  'CCARE': 'CCARE',
  'LEAD': 'LEAD'
}

export const STEP_TYPE = {
  approval: 'a',
  email : 'e',
  upload_img : 'upload_img',
  email_custom_file : 'e2',
  receipt: 'r',
  payment_link: 'pay',
  code: 'code',
  code2: 'code2',
  check: 'check',
  inspection: 'insp',
  feedback: 'f',
  sublist: 'sub',
  courier: 'courier',
  welcome: 'welcome',
}