import React, { useContext } from 'react';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import { AppContext } from './AppContextProvider';

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
        [theme.breakpoints.down('md')]: {
          marginRight: 0,
        }
    },
    hide: {
        display: 'none',
    },
    title: {
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
          flexGrow: 1,
          alignSelf: 'center',
          marginTop: 8,
          fontSize: 14,
          marginLeft: theme.spacing(2),
          textAlign: 'left'
        }
    },
    onlyDesktop: {
        display: 'inherit',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    }
}));

export default function MyAppBar(props){

    const classes = useStyles();
    // const history = useHistory();
    const {state, handleLogout} = useContext(AppContext);

    return (
        <AppBar position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: state.open,
            })}
            >
            <Toolbar disableGutters={!state.open}>
                { state.isAuthenticated && 
                    <Typography variant="h4" color="inherit" className={classes.title} noWrap>
                        {state.title}
                    </Typography>
                }
                {!state.isAuthenticating && (state.isAuthenticated ? 
                    <Tooltip title="Logout">
                        <IconButton color="inherit" style={{position: "absolute", right: 20}}  onClick={handleLogout}><ExitToAppOutlinedIcon/></IconButton>
                    </Tooltip>
                :
                    null
                )}
            </Toolbar>
        </AppBar>
    );
}