import React, { useContext, useState } from "react";
import "../css/home.css";
import {
	Avatar,
	Grid,
	Step,
	StepLabel,
	Stepper,
	withStyles, 
	makeStyles,
	StepConnector, 
	StepContent
} from '@material-ui/core';
import { AppContext } from "../AppContextProvider";
import { Cancel, CheckCircle } from "@material-ui/icons";
import PropTypes from 'prop-types';
// import CustomStepContent from "../LeadPage/CustomStepContent";
import { grey } from "@material-ui/core/colors";
import clsx from 'clsx';
import CustomStepLabel from "../LeadPage/CustomStepLabel";
import { LeadService } from "../Services/LeadService";
import CustomStepContent from "../LeadPage/CustomStepContent";
import { USERTYPES, STEP_TYPE } from "../Constants";

function Home(props){

	const {state, setTitle, showProgressDialog, closeProgressDialog, showConfirmDialog1D, closeConfirmDialog1D, handleLogout} = useContext(AppContext);
	const [completedSteps, setCompletedSteps] = useState(0);
	const [totalSteps, setTotalSteps] = useState(0);
	const [lead, setLead] = useState({});
	const [step, setStep] = React.useState(null);

	React.useEffect(() => {
		if(state.isAuthenticated){
			setTitle(`Onboarding Status  ${completedSteps}/${totalSteps}`);
		}
		return () => {
		}
	}, [state.isAuthenticated, true]);

	React.useEffect(() => {
		fetchData();
	}, [state.user.id]);

	const fetchData = () => {
		LeadService.getLeadStatus(state.user.id)
			.then(data => {
				setLead(data);
				setTotalSteps(data.steps.length);
				setCompletedSteps(data.steps.filter(f => f.approved === 1).length);
				let completed = data.steps.filter(s => s.approved !== null && s.approved !== 0).length;
				setStep(data.steps[completed]);
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog1D('Something went wrong. Please try again or contact Acumen Team.', undefined, () => {
					closeConfirmDialog1D();
					handleLogout();
				});
			});
	}

	React.useEffect(() => {
		setTitle(`Onboarding Status  ${completedSteps}/${totalSteps}`);
	}, [completedSteps, totalSteps]);

	const getStepLabel = (step) => {
		if(!step.approved || step.approved === 0){
			return <CustomStepLabel step={step} lead={lead}/>
		} else {
			return <CustomStepLabel step={step} lead={lead}/>
		}
	}

	const handleChange = (field, value) => {
		// console.log(field, value);
		setStep(st => ({...st, [field] : value}))
	}

	const saveWithData = (fields) => {
		Object.keys(fields).forEach(field => {
			step[field] = fields[field];
		})
		save();
	}

	const save = () => {
		if(step.step_ui_type === STEP_TYPE.email || step.step_ui_type === STEP_TYPE.code 
			|| step.step_ui_type === STEP_TYPE.check || step.step_ui_type === STEP_TYPE.sublist 
			|| step.step_ui_type === STEP_TYPE.payment_link || step.step_ui_type === STEP_TYPE.inspection
			|| step.step_ui_type === STEP_TYPE.receipt || step.step_ui_type === STEP_TYPE.feedback 
			|| step.step_ui_type === STEP_TYPE.email_custom_file || step.step_ui_type === STEP_TYPE.upload_img
			|| step.step_ui_type === STEP_TYPE.courier || step.step_ui_type === STEP_TYPE.code2) {
			step.approved = 1;
		}
		showProgressDialog('Updating...');
		LeadService.saveStep(lead.id, step)
		.then(data => {
			closeProgressDialog();
			if(data.success){
				setStep(null);
				fetchData();
			}
		})
		.catch(error => {
			console.log(error);
			closeProgressDialog();
			showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
				closeConfirmDialog1D();
			});
		});
	}


	return (
		<div className="App container">
			<div className="Home">
				<div className="lander">
					<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16}}>
						<Grid container justify="center" spacing={2} alignItems="center">
							<Grid item xs={12} lg={8}>
								<Stepper orientation="vertical" activeStep={completedSteps} connector={<StatusConnector/>}>
									{
										lead && lead.steps && lead.steps.map((step1, index) => (
											<Step key={index}>
												<StepLabel StepIconComponent={CustomStepIcon} error={step1.approved ? step1.approved === -1 : false} completed={step1.approved ? step1.approved === 1 : false}>
													{getStepLabel(step1)}
												</StepLabel>
												{
													step1.ownership === USERTYPES.LEAD && (
													<StepContent>
														<CustomStepContent step={step} save={saveWithData} handleChange={handleChange}/>
													</StepContent>
													)
												}
											</Step>
										))
									}
								</Stepper>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		</div>
	);
}

const StatusConnector = withStyles({
	alternativeLabel: {
	  top: 10,
	  left: 'calc(-50% + 16px)',
	  right: 'calc(50% + 16px)',
	},
	active: {
	  '& $line': {
		borderColor: '#4caf50',
		borderTopWidth: 8
	  },
	},
	completed: {
	  '& $line': {
		borderColor: '#4caf50',
		borderLeftWidth: 2
	  },
	},
	line: {
	  borderColor: '#eaeaf0',
	  borderTopWidth: 3,
	  borderRadius: 1,
	},
	vertical: {
		marginLeft: 11
	}
  })(StepConnector);

const customStepIconStyles = makeStyles(theme => ({
	root: {
	  color: 'black',
	  display: 'flex',
	  height: 22,
	  alignItems: 'center',
	},
	active: {
	  color: '#90caf9',
	},
	circle: {
	  width: 25,
	  height: 25,
	  borderRadius: '50%',
	  backgroundColor: '#eaeaf0',
	  fontSize: 12,
	  color: grey[700]
	},
	completed: {
	  color: '#4caf50',
	  zIndex: 1,
	  fontSize: 18,
	  width: theme.spacing(3),
	  height: theme.spacing(3),
	  borderRadius: '50%',
	},
	cancel: {
	  color: '#f44336',
	  zIndex: 1,
	  fontSize: 18,
	  width: theme.spacing(3),
	  height: theme.spacing(3),
	  borderRadius: '50%',
	},
}));

function CustomStepIcon(props) {
	const classes = customStepIconStyles();
	const { active, completed, error, icon } = props;
	// console.log(props);
  
	return (
	  <div
		className={clsx(classes.root, {
		  [classes.active]: active,
		})}
	  >
		{
			completed ? 
				<CheckCircle className={classes.completed} /> 
			: 
				error ? 
				<Cancel className={classes.cancel} /> 
				:
				<Avatar className={classes.circle}>{icon}</Avatar>
		}
	  </div>
	);
}
  
CustomStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool,
};


export default Home;