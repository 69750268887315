import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, Grid, TextField } from '@material-ui/core';
import { AppContext } from '../AppContextProvider';
import moment from 'moment';
import SingleDate from '../components/SingleDate';
import { STEP_TYPE } from '../Constants';
import { format_without_time } from '../Utils';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2)
    },
	right: {
        flex: 0.5, 
        textAlign: 'end'
    },
    time: {
        position: 'relative',
        top: '50%'
    },
    section: {
        flex: 0.5
    },
    sectionFull: {
        flex: 1,
        marginTop: 6,
        marginBottom: 6
    },
    formControlAutoComplete: {
        zIndex: 3,
    },
    gridList: {
        margin: 16,
        width: '100%'
    },
    image: {
		margin: 12,
		position: 'relative',
		height: 200,
		width: '90% !important',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &Mui-focusVisible': {
			zIndex: 1,
			'& $imageBackdrop': {
				opacity: 0.7,
			},
			'& $imageMarked': {
				opacity: 0,
			},
			'& $imageTitle': {
				border: '4px solid currentColor',
			},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
        // bottom: 0,
        bottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
}));

function CourierBlock({step, save, handleChange}){
    const classes = useStyles();
    const {showProgressDialog, showSnackBar, showConfirmDialog, closeConfirmDialog} = useContext(AppContext);
    const [submitted, setSubmitted] = useState(false);

    const change = (event) => {
        // console.log(event.target.value);
        handleChange(event.target.name, event.target.value);
    }

    const isEmpty = (field) => {
        return (!field || field.length === 0 || !field.trim());
    };
    

    const saveStep = () => {
        if(isEmpty(step.field1) || isEmpty(step.field2) || isEmpty(step.field3)){
            showSnackBar('Please fill in all the Required Fields', 'error');
        } else {
            showConfirmDialog('Confirm tracking details?', undefined, () => {
                closeConfirmDialog();
                setSubmitted(true);
                showProgressDialog(`Updating details...`);
                save({});
            })
        }
    }

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="flex-start">
                <Grid item xs={12} lg={3}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Name of Courier"
                            value={step.field1 || ''}
                            margin="dense"
                            name="field1"
                            required
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 30
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <SingleDate
                        margin="dense"
                        required
                        label="Date of Dispatch"
                        date={step.field2 ? moment(step.field2): null}
                        setDate={date => handleChange('field2', date.format(format_without_time))}
                        /> 
                </Grid>
                <Grid item xs={12} lg={3}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="POD number (Tracking No)"
                            value={step.field3 || ''}
                            margin="dense"
                            name="field3"
                            onChange={change}
                            required
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 20
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Tracking Link"
                            value={step.field4 || ''}
                            margin="dense"
                            name="field4"
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 200
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={2}>
                    <Button 
                        fullWidth 
                        color="primary" 
                        variant="contained" 
                        onClick={saveStep} 
                        disabled={submitted}
                        style={{marginTop: 8}}>Save</Button>
                </Grid>
            </Grid>
        </div>
    );
}

function DummyBlock(){
    const classes = useStyles();

    return (
        <div className={classes.sectionFull}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">
                {/* <Grid item xs={12} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Comments"
                            value={step.comments || ''}
                            margin="dense"
                            name="comments"
                            onChange={change}
                            aria-describedby="outlined-weight-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputProps: {
                                    maxLength: 100
                                },
                            }}
                        />
                    </FormControl>
                </Grid> */}
            </Grid>
        </div>
    );
}

const CustomStepContent = (props) => {
    const {step, handleChange, save} = props;
    const classes = useStyles();
    const getCenterBlock = () => {
        switch(step.step_ui_type){
            case STEP_TYPE.courier:
                return <CourierBlock step={step} save={save} handleChange={handleChange}/>;
            default:    
                return <DummyBlock/>;
        }
    }
    
    return (
        <div className={classes.root}>
            {step && getCenterBlock()}
        </div>
    )
};

export default CustomStepContent;