import React, { useContext } from "react";
import "../css/login.css";
import { UserService } from "../Services/UserService";
import { 
  Grid, 
  makeStyles, 
  TextField, 
  withStyles
 } from "@material-ui/core";
import {  FormControl, Button, IconButton, } from "@material-ui/core";
import clsx from 'clsx';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from "react-router-dom";
import { AppContext } from "../AppContextProvider";
import { useState } from "react";

const useStyles = makeStyles(theme =>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 300,
  },
  right: {
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
    textAlign: 'center'
  }
}));

export default function Login(props){

  const classes = useStyles();
  const history = useHistory();
  const {setTitle, 
    showSnackBar, 
    setAuthenticating, 
    setLinearLoading, 
    updateUser, 
    userHasAuthenticated,
    mobileView
  } = useContext(AppContext);

  const [showPAN, setShowPAN] = useState(false);
  const [mobile, setMobile] = useState("");
  const [pan, setPAN] = useState("");

  React.useEffect(() => {
    setTitle('');
    const user = localStorage.getItem('acumen.status.user') !== null ? JSON.stringify(localStorage.getItem('acumen.status.user')) : null;
    const session = localStorage.getItem('acumen.status.session');
    if(user !== null && session !== null) {
      history.push("/status");
    } else {
      setAuthenticating(false);
    }
  }, [true])

  const validateForm = () => {
    return mobile.length > 9 && pan.length === 10;
  }

  const handleChange = event => {
    if(event.target.name === 'pan'){
      setPAN(event.target.value)
    } else if(event.target.name === 'mobile'){
      setMobile(event.target.value)
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    if(validateForm()){
      try {
        setLinearLoading(true);
        UserService.authenticate({mobile: mobile, pan: pan})
        .then((data) => {
            if (data.id) {
                updateUser(data);
                localStorage.setItem('acumen.status.user', JSON.stringify(data));
                localStorage.setItem('acumen.status.session', data['sysbrew-x-token']);
                userHasAuthenticated(true);
                history.push("/status");
            } else if(data.status === 0){
              showSnackBar("Your account has been deactivated. Contact Admin" , 'error');
            } else if(data.message){
              showSnackBar(data.message , 'error');
            }
            setLinearLoading(false);
        })
        .catch((error) => {
          showSnackBar("Failed to Login. invalid credentials" , 'error');
          setLinearLoading(false);
        });
      } catch (e) {
        alert(e.message);
        setLinearLoading(false);
      }
    } else {
      showSnackBar("Please Enter correct Mobile no and PAN no" , 'error');
    }
  }

  const handleClickShowPassword = () => {
    setShowPAN(prevState => !prevState);
  };

  return (
    <div className="Login" style={{height: '100vh', marginLeft: mobileView ? 0 : 16}}>
      <Grid container direction="row" justify="center" alignItems="center" style={{height: '100vh'}}>
        <Grid item xs={12} lg={8}>
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Logo" height={mobileView ? 150: 450}/>
        </Grid>
        <Grid item xs={12} lg={4}>
            <div className={classes.right}>
              <Grid container justify="center" alignItems="center" style={{position: 'relative', top: mobileView ? '30%': '30%'}}>
                <Grid item xs={12}>
                  <img src={process.env.PUBLIC_URL + '/images/icon.png'} alt="Logo" height={60} style={{marginTop: 20, marginBottom: 30}}/>
                  <form onSubmit={handleSubmit}>
                    <FormControl className={clsx(classes.margin, classes.textField)}  variant="outlined">
                      <CssTextField
                        variant="outlined"
                        id="standard-adornment-mobile"
                        type={'mobile'}
                        value={mobile}
                        label="Mobile"
                        name="mobile"
                        onChange={handleChange}
                        style={{width: '80%'}}
                      />
                    </FormControl>
                    <FormControl className={clsx(classes.margin, classes.textField)}>
                      <div>
                      <CssTextField
                        variant="outlined"
                        id="standard-adornment-pan"
                        type={showPAN ? 'text' : 'password'}
                        value={pan}
                        name="pan"
                        label="PAN"
                        onChange={handleChange}
                        InputProps={{
														'aria-label': 'off_add1',
														inputProps: {
															maxLength: 10,
															style:{
																textTransform: 'uppercase'
															}
														}
                        }}
                        style={{float: 'left', width: '80%'}}
                      />
                        <IconButton
                          aria-label="toggle pan visibility"
                          onClick={handleClickShowPassword}
                          style={{float: 'right', color: 'white'}}
                          //onMouseDown={handleMouseDownPassword}
                        >
                          {showPAN ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </div>
                    </FormControl>
                    <FormControl className={clsx(classes.margin, classes.textField)}>
                      <Button variant="text" style={{color: 'white'}} onClick={handleSubmit} size="large">
                        Check Status
                      </Button>
                    </FormControl>
                  </form>
                </Grid>
              </Grid>
            </div>
        </Grid>
      </Grid>
    </div>
  );
}

const CssTextField = withStyles(theme => ({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiFormLabel-root': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
}))(TextField);